import React from "react";
import Icons from "../Constants/Icons";
import Images from "../Constants/Images";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Components/Language/LanguageSelector";

const Servicesdata = [{
    name: "id_1_Services",
    Images: Images.Services3,
    title: "id_1_Services_title",
    title1: "id_2_Services_title",
    title2: "id_3_Services_title"

},
{
    name: "id_1_Blood",
    Images: Images.Services2,
    title: "id_1_Blood_title",
    title1: "id_2_Blood_title",
    title2: "id_3_Blood_title"
},
{
    name: "id_1_taking",
    Images: Images.Services,
    title: "id_1_taking_title",
    title1: "id_2_taking_title",
    title2: "id_3_taking_title"
},
{
    name: "id_1_other",
    Images: Images.Services1,
    title: "id_1_other_title",
    title1: "id_2_other_title",
    title2: "id_3_other_title"
},


]
const Home = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="relative w-full h-[816px] max-[767px]:h-fit Rectanglehome">


                <div className="container h-[816px]  max-[767px]:h-[450px]  relative z-20">
                    <header className="text-center relative flex items-center">
                        <img src={Icons.StellaLogo} className="text-center m-auto w-[356px] h-[165px]" />

                        <div className="absolute right-0 ">
                            <LanguageSelector></LanguageSelector>
                        </div>
                    </header>
                    <div className="flex gap-2 h-[650px]  max-[767px]:h-[400px] items-center ">
                        <div className="w-1/2 max-[767px]:w-full ">
                            <div className="montserrat text-64 font-bold" >{t("id_1_text")}</div>
                            <div className="Helvetica text-22 max-w-[470px] mt-4" >{t("id_1_title")}<span className="br block"></span>{t("id_2_title")}</div>
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
                <img src={Images.bannerhome} className=" w-1/2 h-[700px] z-10  absolute right-0 bottom-0   object-contain  object-bottom max-[1535px]:object-center max-[767px]:relative max-[767px]:h-auto max-[767px]:w-full " />
                <img src={Images.ic_stella} className="w-[50%] absolute right-0 top-0 h-full max-[900px]:h-auto object-cover" />

            </div>

            <div className="container">
                <div className="Helvetica text-64 font-bold w-full text-center margin-my border-b-2 border-customBlue pb-7 text-customBlue"  >{t("id_3_text")}</div>
                <div className="grid gap-3 -mt-6" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))" }}>
                    {Servicesdata.map((items, index) =>
                        <div key={index}>
                            <img src={items.Images} className="w-full" />
                            <div className="text-30 Helvetica text-customBlue font-bold capitalize text-center my-4">{t(items.name)}</div>
                            <div className="text-20 Helvetica text-center capitalize supports_sd" >{t(items.title)}<span className="br block" /> {t(items.title1)} <span className="br block" /> {t(items.title2)}</div>

                        </div>
                    )}
                </div>
                <div className="w-full text-center margin-my">
                    <button className="capitalize text-30 Helvetica bg-customBlue text-white px-24  rounded-full py-2 ">{t("id_1_button")}</button>
                </div>
            </div>

            <div className="container">
                <div className="Helvetica text-64 font-bold w-full text-center margin-my border-b-2 border-customBlue pb-7 text-customBlue uppercase"  >{t("id_1_About")}</div>
                <div>
                    <div className="text-20 Helvetica  capitalize text-center mb-16 -mt-12 max-w-[950px] mx-auto max-[767px]:mt-2">
                        {t("id_2_About")}
                    </div>

                </div>

            </div>


            <div className="bg-customBlue text-white rounded-tr-[175px] pb-[70px]  -mb-[175px] z-20 relative">
                <div className="container">
                    <div className="Helvetica text-64 font-bold w-full text-center py-[70px]"  >{t("id_1_Stella_Fast")}</div>


                    <div className="flex justify-between flex-wrap gap-3 items-center min_home_section" style={{ }}>
                        <div>
                            <div className="Helvetica text-48 font-bold">{t("id_1_Fast")}</div>
                            <div className="Helvetica text-25 mt-3">{t("id_2_Fast")}<span className="br block" />{t("id_3_Fast")}<span className="br block" />{t("id_4_Fast")}</div>

                        </div>
                        <div className="">
                            <div>
                                <div className="Helvetica text-48 font-bold">{t("id_1_Safe")}</div>
                                <div className="Helvetica text-25 mt-3">{t("id_2_Safe")}<span className="br block" />{t("id_3_Safe")}<span className="br block" />{t("id_4_Safe")}</div>
                            </div>
                        </div>
                        <div className="">
                            <div>
                                <div className="Helvetica text-48 font-bold">{t("id_1_Easy")}</div>
                                <div className="Helvetica text-25 mt-3">{t("id_2_Easy")}<span className="br block" />{t("id_3_Easy")}<span className="br block" />{t("id_4_Easy")}</div>
                            </div>
                        </div>

                        {/* <div>
                            <img src={Images.Fast2} className="w-full" />
                        </div> */}
                    </div>
                </div>
            </div>


        </div>
    )
}
export default Home;